import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";

import fetchJsonp from "fetch-jsonp";

import SmartFeedIndividual from "@accrosoft-ltd/vf-careers-site-theme/src/modules/SmartFeedIndividual";
import SmartFeedList from "../modules/SmartFeedList";
import SmartFeedSavedList from "../modules/SmartFeedSavedList";
import SmartFeedNotFound from "@accrosoft-ltd/vf-careers-site-theme/src/modules/SmartFeedNotFound";

const Vacancies = (props) => {
  const {
    pageContext: { config },
    location,
  } = props;

  const siteConfig = config;

  const [apiKey, setApiKey] = useState(
    props.smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID
  );
  const [gdprKey, setGdprKey] = useState(
    props.smartfeedID ||
      siteConfig.gdprKey ||
      siteConfig.apiKey ||
      siteConfig.smartfeedID
  );
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? "group" : "id"
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = "GetCompanyDetails";

      if (groupOrIdParam === "group") {
        companyDetailsEndpoint = "GetGroupDetails";
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            "/CareerPage/" +
            companyDetailsEndpoint +
            "?id=" +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                "/CareerPage/" +
                "GetApplicantGetwayURL" +
                "?companyId" +
                "=" +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, "error");
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, "error");
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError("No API key was provided.");
    }
  }, [apiKey, groupOrIdParam]);

  return (
    <Router>
      <SmartFeedNotFound
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path={
          siteConfig.companyCareersSite
            ? "/vacancies/vacancy/:id"
            : "/vacancies/:smartfeedID/vacancy/:id"
        }
        default
      />
      <SmartFeedIndividual
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path={
          siteConfig.companyCareersSite
            ? "/vacancies/vacancy/:id"
            : "/vacancies/:smartfeedID/vacancy/:id"
        }
      />
      <SmartFeedIndividual
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path={
          siteConfig.companyCareersSite
            ? "/vacancies/vacancy/:id/:vacancyTitlePath"
            : "/vacancies/:smartfeedID/vacancy/:id/:vacancyTitlePath"
        }
      />
      <SmartFeedSavedList
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path={
          siteConfig.companyCareersSite
            ? "/vacancies/saved"
            : "/vacancies/:smartfeedID/saved"
        }
      />
      <SmartFeedList
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path={
          siteConfig.companyCareersSite
            ? "/vacancies/"
            : "/vacancies/:smartfeedID/"
        }
      />
    </Router>
  );
};

export default Vacancies;
